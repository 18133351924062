@import '~bootstrap/scss/bootstrap.scss';

/* nabbar上部固定対策 */
body {
  margin: 50px 0px 0px 0px !important;
}

/* テーブルヘッダの垂直位置を中央固定 */
thead > tr > th {
  @extend .align-middle;
}

/* メインコンテンツ左右余白除外 */
.container {
  max-width: 98% !important;
}

/* 幅100px用クラス */
.w100px {
  width: 100px;
}

/* モーダルXXLサイズ */
.modal-xxl {
  @extend .modal-xl;
  max-width: 95%;
}

/* レキットベンキーザー・ジャパン社メインカラー(Scss化は後程) */
.bg-rb {
  background-color: #ea3592 !important;
}
// 本番とステージングで混同しないように色分け
.bg-rb-stg {
  background-color: #0000ee !important;
}

a.bg-rb:hover, a.bg-rb:focus,
button.bg-rb:hover,
button.bg-rb:focus {
  background-color: #d61679 !important;
}
